<template>
  <ion-header class="ion-no-border">
    <ion-toolbar mode="md">
      <ion-title>
        <strong v-if="id">Ordainketa #{{ id }}</strong>
        <strong v-else>Ordainketa berria</strong>
      </ion-title>
      <ion-buttons slot="end">
        <ion-button @click="closeModal()">
          <ion-icon slot="icon-only" :icon="closeOutline"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-item>
      <ion-label position="floating">Ordainketa izena</ion-label>
      <ion-input
        required="true"
        :value="name"
        @ionInput="name = $event.target.value"
      ></ion-input>
    </ion-item>

    <ion-item>
      <ion-label position="floating">Kopurua (€)</ion-label>
      <ion-input
        required="true"
        :value="amount"
        @ionInput="amount = $event.target.value"
      ></ion-input>
    </ion-item>

    <ion-item>
      <ion-label>Ordainduta?</ion-label>
      <ion-toggle mode="ios" @ionChange="paid = !paid" :checked="paid">
      </ion-toggle>
    </ion-item>

    <p class="notes" v-if="!id">
      <ion-icon :icon="warningOutline" />
      <span
        >Txartel bidez kobratzeko, lehendabizi komeni da ordainketa gordetzea
        eta gorde ondoren txartel bidez ordainketa burutzea.</span
      >
    </p>

    <div style="margin-top: 30px">
      <ion-button
        @click="scanCard"
        color="light"
        expand="block"
        mode="ios"
        size="medium"
        ><ion-icon :icon="card" style="margin-right: 5px;" /> Txartelaz ordaindu
      </ion-button>
    </div>

    <messages v-if="toast" :toast="toast" @closeToast="closeToast"></messages>
  </ion-content>

  <ion-footer class="ion-no-border" style="padding: 15px">
    <ion-toolbar>
      <div class="buttons-footer">
        <ion-button
          v-if="!stripeToken"
          @click="normalPay"
          mode="ios"
          size="medium"
          >Ordainketa gorde
        </ion-button>

        <ion-button
          v-else
          @click="normalPay"
          expand="block"
          mode="ios"
          color="warning"
          size="medium"
          >Kobratu <span class="amount">{{ amount }}€</span>
        </ion-button>

        <ion-button
          v-if="id"
          @click="deleteHandle(id)"
          color="danger"
          mode="ios"
          size="medium"
          >Ezabatu
        </ion-button>
      </div>
    </ion-toolbar>
  </ion-footer>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonFooter,
  IonItem,
  IonButtons,
  IonButton,
  IonLabel,
  IonInput,
  IonIcon,
  IonToggle,
  modalController,
  loadingController,
} from "@ionic/vue";
import { closeOutline, card, warningOutline } from "ionicons/icons";
import axios from "axios";
import { useStore } from "vuex";
import { Stripe } from "@ionic-native/stripe";
import { CardIO } from "@ionic-native/card-io";

import errors from "@/middlewares/errors.js";
import Messages from "@/components/Messages.vue";

import { ref, onMounted } from "vue";
export default {
  name: "PaymentForm",
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonFooter,
    IonItem,
    IonButtons,
    IonButton,
    IonLabel,
    IonInput,
    IonIcon,
    IonToggle,
    Messages,
  },
  props: {
    payment: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const id = ref(null);
    const clientId = ref(null);
    const name = ref(null);
    const amount = ref(null);
    const paid = ref(false);
    const stripeToken = ref(null);
    const lastUpdate = ref(null);
    const toast = ref(null);
    const scanedCard = ref(null);

    const api = ref(process.env.VUE_APP_API);

    onMounted(() => {
      if (props.payment.id) {
        id.value = props.payment.id;
        clientId.value = props.payment.client_id;
        name.value = props.payment.name;
        amount.value = props.payment.amount;
        paid.value = props.payment.paid == 1 ? true : false;
        lastUpdate.value = props.payment.updated_at;
      } else {
        clientId.value = props.payment.client;
      }
    });

    const closeModal = async () => {
      return modalController.dismiss();
    };

    const closeToast = () => {
      toast.value = null;
    };

    // stripe
    const scanCard = async () => {
      CardIO.canScan().then((res) => {
        if (res) {
          let options = {
            requireExpiry: true,
            requireCVV: true,
            requirePostalCode: false,
            suppressScan: false,
            suppressConfirmation: false,
            useCardIOLogo: true,
            keepApplicationTheme: true,
            guideColor: "#84d728",
          };

          CardIO.scan(options).then((getCard) => {
            scanedCard.value = getCard;
            stripePay();
          });
        }
      });
    };

    const stripePay = async () => {
      const loading = await loadingController.create({
        mode: "ios",
        message: "Stripe kargatzen...",
      });

      await loading.present();

      const publicKey = process.env.VUE_APP_STRIPE;
      Stripe.setPublishableKey(publicKey);

      // const card = {
      //   number: "4242424242424242", // 16-digit credit card number
      //   expMonth: 12, // expiry month
      //   expYear: 2021, // expiry year
      //   cvc: "220", // CVC / CCV
      //   currency: "EUR", // Three-letter ISO currency code (optional)
      // };

      // real
      const card = {
        number: scanedCard.value.cardNumber, // 16-digit credit card number
        expMonth: scanedCard.value.expiryMonth, // expiry month
        expYear: scanedCard.value.expiryYear, // expiry year
        cvc: scanedCard.value.cvv, // CVC / CCV
        currency: "EUR", // Three-letter ISO currency code (optional)
      };

      Stripe.createCardToken(card).then(async (stripe) => {
        stripeToken.value = stripe.id;
        loading.dismiss();
      });
    };
    // end stripe

    const normalPay = async () => {
      const loading = await loadingController.create({
        mode: "ios",
      });

      await loading.present();

      let params = {
        id: id.value,
        client_id: clientId.value,
        name: name.value,
        amount: amount.value,
        paid: paid.value,
        stripeToken: stripeToken.value,
      };

      if (!name.value || !amount.value) {
        toast.value = {
          type: "danger",
          message: "Izena eta kopurua bete",
        };

        loading.dismiss();
        return true;
      }

      axios
        .post(`${api.value}payments`, params)
        .then((res) => {
          if (res.data.error) {
            toast.value = {
              type: "danger",
              message: `Erroreak errepasatu:
                ${errors(res.data.error)}`,
            };
            loading.dismiss();
            return true;
          }

          store.dispatch("_clientReload", clientId.value).then(() => {
            store.commit("_RESET_PAYMENTS", true);
          });

          let message = "Ordainketaren datuak gorde dira";
          if (stripeToken.value)
            message = "Ordainketa ondo kobratu da Stripe bidez";

          toast.value = {
            type: "success",
            message,
          };

          loading.dismiss();
        })
        .catch(() => {
          toast.value = {
            type: "danger",
            message: `Zerbitzariko errore bat gertatu da...`,
          };
          loading.dismiss();
        });
    };

    const deleteHandle = async () => {
      const loading = await loadingController.create({
        mode: "ios",
      });
      await loading.present();

      axios
        .delete(`${api.value}payments/${id.value}`)
        .then(() => {
          store.dispatch("_clientReload", clientId.value).then(() => {
            store.commit("_RESET_PAYMENTS", true);
          });

          toast.value = {
            type: "success",
            message: "Ordainketa ezabatu da",
          };

          loading.dismiss();
        })
        .catch(() => {
          toast.value = {
            type: "danger",
            message: `Zerbitzariko errore bat gertatu da...`,
          };
          loading.dismiss();
        });
    };

    return {
      normalPay,
      deleteHandle,
      stripePay,
      stripeToken,
      scanCard,
      scanedCard,
      closeModal,
      closeOutline,
      warningOutline,
      clientId,
      name,
      amount,
      paid,
      id,
      card,
      lastUpdate,
      toast,
      closeToast,
    };
  },
};
</script>

<style lang="scss">
.amount {
  margin-left: 10px;
  font-weight: bold;
}
.buttons-footer {
  display: flex;
  justify-content: space-around;
  ion-button {
    width: 100%;
  }
}

.notes {
  display: flex;
  align-items: flex-start;
  background: lighten(#ffca22, 30%);
  padding: 10px;
  font-size: 14px;
  border-radius: 3px;
  margin: 30px 0;

  ion-icon {
    font-size: 20px;
    width: 100%;
    max-width: 40px;
    margin-right: 5px;
  }
}
</style>
